import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./i18n/config";
import { CookiesProvider } from "react-cookie";
function importBuildTarget() {
  if (process.env.REACT_APP_TARGET === 'teikyo') {
    return import('./TeikyoApp');
  } else if (process.env.REACT_APP_TARGET === 'investment') {
    return import('./InvestmentApp');
  } else if (process.env.REACT_APP_TARGET === 'openskill' || process.env.REACT_APP_TARGET === 'bering') {
    return import('./InvestmentApp');
  } else {
    return import('./App');
  }
}
importBuildTarget().then(({ default: Target }) => {
  ReactDOM.render(
    <CookiesProvider>
      <Target />
    </CookiesProvider>,
    document.getElementById("root") as HTMLElement,
  );
});
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/build/sw.js");
  });
} // If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
